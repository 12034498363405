// Orbita variables
// Also used to configure Bootstrap


// Colors

$white:  #fff !default;
$black:  #000 !default;
$red:    #d9534f !default;
$orange: #f0ad4e !default;
$yellow: #ffd500 !default;
$green:  #5cb85c !default;
$blue:   #0275d8 !default;
$teal:   #5bc0de !default;
$pink:   #ff5b77 !default;
$purple: #613d7c !default;

$color-brand-dark: #01315f;
$color-brand-bright: #26a1ff;
$color-brand-subtle: #eff3f6;
$color-brand-gray: #d8d8d8;
$color-brand-gray-light: #e5e5e5;
$color-brand-gray-subtle: #fafafa;

$color-brand-success: #4c930d;
$color-brand-error: #d81e30;

$brand-primary:     $color-brand-bright;
$brand-success:     $color-brand-success;
$brand-info:        $color-brand-bright;
$brand-warning:     $color-brand-error;
$brand-danger:      $color-brand-error;
$brand-inverse:     $color-brand-dark;

// Create grayscale
$gray-dark:                 #292b2c !default;
$gray:                      #464a4c !default;
$gray-light:                #636c72 !default;
$gray-lighter:              #eceeef !default;
$gray-lightest:             #f7f7f9 !default;


// Type

$font-family-sans-serif: 'Roboto', sans-serif;
$font-family-base: $font-family-sans-serif;

$font-size-base: 14px;
$font-size-lg:   16px;
$font-size-sm:   13px;
$font-size-xs:   12px;

$body-color: #252525;


// Styles

$orbita-border-light: 1px solid #ededed;


// Components

$border-radius:          .25rem !default;
$border-radius-lg:       .3rem !default;
$border-radius-sm:       .2rem !default;


// Buttons

$btn-primary-color:              $white !default;
$btn-primary-bg:                 $color-brand-bright;
$btn-primary-border:             $color-brand-bright;


// Grid

$grid-gutter-width-base: 30px;
$grid-gutter-half: ($grid-gutter-width-base / 2);


// Product (cat) nav

$product-nav-width: 230px;
$product-nav-bg-color: $color-brand-gray-subtle;
$product-nav-height: 600px;
$product-nav-border-color: #ededed;
$product-nav-border: 1px solid $product-nav-border-color;